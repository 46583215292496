import React from "react";
import Link from "./Link";
import { Routes } from "../constants/Routes";
import { withScreenXY } from "../hooks/withScreenXY";
import MenuDrawer from "./MenuDrawer";

const Header = (props) => {
  const [menu, setMenu] = React.useState(false);
  const { width } = props.screenXY;
  const isSmallScreen = width < 800;

  const isActiveTab = (pathname) => {
    return window.location.pathname === pathname
      ? "underline underline-offset-8 "
      : null;
  };

  const handleMenu = () => {
    setMenu(!menu);
  };
  // console.log("screenWidth", width);

  return (
    <div className="flex justify-between items-center">
      <h1 className="text-[40px]">
        <Link
          className={
            "py-4 px-8 hover:underline-offset-[15px] "
            // + " border-2 rounded-full  shadow-2xl shadow-indigo-500"
          }
          href={process.env.REACT_APP_BASE_URL}
          self
        >
          Navin<sup className="text-xl">v2</sup>
        </Link>
      </h1>
      {isSmallScreen && (
        <img
          src="/menu.svg"
          alt="Menu"
          width="30"
          height="30"
          className="mr-4"
          onClick={handleMenu}
        />
      )}
      {isSmallScreen ? (
        menu ? (
          <MenuDrawer isActiveTab={isActiveTab} />
        ) : null
      ) : (
        <ul className="flex flex-col sm:flex-row gap-x-8 text-2xl">
          <li>
            <Link
              className={isActiveTab(Routes.about)}
              href={process.env.REACT_APP_BASE_URL + Routes.about}
              self
            >
              About
            </Link>
          </li>
          <li>
            <Link
              className={isActiveTab(Routes.skills)}
              href={process.env.REACT_APP_BASE_URL + Routes.skills}
              self
            >
              Skills
            </Link>
          </li>
          <li>
            <Link
              className={isActiveTab(Routes.work)}
              href={process.env.REACT_APP_BASE_URL + Routes.work}
              self
            >
              Work
            </Link>
          </li>
          <li>
            <Link
              className={isActiveTab(Routes.education)}
              href={process.env.REACT_APP_BASE_URL + Routes.education}
              self
            >
              Education
            </Link>
          </li>
          <li>
            <Link
              className={isActiveTab(Routes.contact)}
              href={process.env.REACT_APP_BASE_URL + Routes.contact}
              self
            >
              Contact
            </Link>
          </li>
          <li>
            <Link
              className={isActiveTab(Routes.misc)}
              href={process.env.REACT_APP_BASE_URL + Routes.misc}
              self
            >
              Misc
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default withScreenXY(Header);
