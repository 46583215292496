import React from "react";
import Header from "./v2/Header.jsx";
import Home from "./v2/Home.jsx";
import Footer from "./v2/Footer.jsx";
import About from "./v2/About.jsx";
import Work from "./v2/Work.jsx";
import { Routes } from "./constants/Routes.js";
import Education from "./v2/Education.jsx";
import Skills from "./v2/Skills.jsx";
import Contact from "./v2/Contact";
import Misc from "./v2/Misc.jsx";

function App() {
  const currentUrl = window.location.pathname;
  const menuClassName = "text-2xl tracking-wider font-thin leading-10";
  return (
    <div className="py-[4%] px-[10%] lg:px-[18%] tracking-widest">
      <Header />
      {currentUrl === Routes.home && (
        <div className="px-7 my-24">
          <Home className={menuClassName} />
        </div>
      )}
      {currentUrl === Routes.about && (
        <div className="px-7 my-24">
          <About className={menuClassName} />
        </div>
      )}
      {currentUrl === Routes.skills && (
        <div className="px-7 my-24">
          <Skills className={menuClassName} />
        </div>
      )}
      {currentUrl === Routes.work && (
        <div className="px-7 my-24">
          <Work className={menuClassName} />
        </div>
      )}
      {currentUrl === Routes.education && (
        <div className="px-7 my-24">
          <Education className={menuClassName} />
        </div>
      )}
      {currentUrl === Routes.contact && (
        <div className="px-7 my-24">
          <Contact className={menuClassName} />
        </div>
      )}
      {currentUrl === Routes.misc && (
        <div className="px-7 my-24">
          <Misc className={menuClassName} />
        </div>
      )}

      <Footer className="px-5 text-xl" />
    </div>
  );
}

export default App;
